
	import VuePictureCropper, {
		cropper
	} from 'vue-picture-cropper'
	import {
		readFileContent
	} from "../util/index.js"
	import {
		ElMessage
	} from 'element-plus'
	export default {
		data() {
			return {
				isloading: false,
				ListData: [],
				edtdirect: 'rtl',
				editopinfo: false,
				editortitle: this.PAGELANG.editortitle,
				showdeldlg: false,
				showcroperdlg: false,
				aspectRatio: 0,
				ImageUrl: '',
				delopt: "1",
				cates: [],
				optcates: [],
				edtprofile: {
					ID: 'add',
					ImageUrl: '',
					Name: '',
					Remark: '',
					Status: true
				},
				delformopt: {
					DelID: '0',
					DelOpt: '1',
					CntToBrand: '0',	//DelpOpt=2时有效
				}
			}
		},
		beforeCreate() {
			this.PAGELANG = this.LANG.pages.Brands;
		},
		methods: {
			onEditorImgLoadError(e) {
				console.log(e);
				this.ImageUrl = '';
			},
			CropImage() {
				this.edtprofile.ImageUrl = cropper.getDataURL();
				this.ImageUrl = this.edtprofile.ImageUrl;
				this.showcroperdlg = false;
			},
			onFileSel(filelist) {
				if(filelist.length <= 0)
					return;
					
				let file = filelist[0];
				
				if(!file.name)
					return;
				
				var index= file.name.lastIndexOf(".");//获取最后一个.的位置
				var ext = file.name.substr(index+1).toLowerCase();//获取后缀
				var pattern = /^(jpg|jpeg|png|gif)$/;
				if(!pattern.test(ext))
				{
					ElMessage({
						message: this.PAGELANG.wjgsbzc,
						type: 'error',
					});
					return false;
				}
			
				readFileContent(file, 'dataUrl').then((contents) => {
					this.croperimg = contents;
					this.showcroperdlg = true;
					this.$refs.uploader.value = '';
				});
				
			},
			onExecCrop() {
				this.editprofile.ImageUrl = cropper.getDataURL();
				this.ImageUrl = this.editprofile.ImageUrl;
				this.showimgeditor = false;
				this.himgupdated = true;
			},
			CloseDrawer() {
				this.editopinfo = false;
			},
			CloseDelDrawer() {
				this.showdeldlg = false;
			},
			SaveBrand() {
				console.log(this.edtprofile);
				this.axios.post(this.actions.savebrand, this.edtprofile).then((response) => {
					console.log(response.data);
					if (response.data.retinfo == "OK") {
						this.$message.success(this.LANG.system.caozuochenggong);
						this.$router.go(0);
					}
				});
			},
			Add(pid) {
				this.edtprofile = {
					ID: 'add',
					Name: '',
					Remark: '',
					Status: true,
					ParentID: 0,
				};
				//console.log(pid);
				this.editopinfo = true;

				if (pid)
					this.edtprofile.ParentID = pid;
				else
					this.edtprofile.ParentID = '0';
				//console.log(this.edtprofile.ParentID);
			},
			Edit(id) {
				if (!id) {
					ElMessage({
						message: this.PAGELANG.canshucuowu,
						type: 'error',
					});
				}

				this.axios.get(this.actions.getbrand, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						id: id,
					}
				}).then((response) => {
					console.log(response.data);
					if (response.data == 'paramerror') {
						ElMessage({
							message: this.PAGELANG.canshucuowu,
							type: 'error',
						});
					} else {
						this.edtprofile.ID = response.data.ID;
						this.edtprofile.ImageUrl = response.data.ImageUrl;
						this.ImageUrl = this.apibase + this.edtprofile.ImageUrl;
						this.edtprofile.Name = response.data.Name;
						this.edtprofile.Remark = response.data.Remark;
						this.edtprofile.ParentID = response.data.ParentID;

						console.log(this.edtprofile);
//
						this.editopinfo = true;
					}

					//this.Refresh();
					//this.ListData = response.data;
				});
				console.log(id);
			},
			Move(op, row) {
				console.log(op);
				console.log(row);
				//row.OpName = '小小';
				//this.$refs['TreeList'].store.states.treeData.value[row.ParentID].loaded = false;
				//console.log(this.$refs['TreeList'].store);
				//console.log(this.$refs['TreeList'].store.states.treeData.value);
				//this.$refs['TreeList'].store.states.treeData.value[row.ID].loaded = false;
				//this.$refs['TreeList'].store.loadOrToggle(row);
				//console.log(this.$refs['TreeList'].store);
				this.axios.get(this.actions.movebrand, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						id: row.ID,
						dir: op
					}
				}).then((response) => {
					console.log(response.data);
					if (response.data == 'paramserror') {
						ElMessage({
							message: this.PAGELANG.canshucuowu,
							type: 'error',
						});
					}
					if (response.data == 'movefailed' && op == 'up') {
						ElMessage({
							message: this.PAGELANG.wufashangyi,
							type: 'warning',
						});
					}
					if (response.data == 'movefailed' && op == 'down') {
						ElMessage({
							message: this.PAGELANG.wufaxiayi,
							type: 'warning',
						});
					}
					this.Refresh();
					//this.ListData = response.data;
				});
			},
			Delete(id) {
				console.log(id);
				this.delformopt = {
					DelID: id,
					DelOpt: '1',
					CntToBrand: '0',	//DelpOpt=2时有效
				};
				this.RefreshDelMvDropdown();
				this.showdeldlg = true;
			},
			DoDelete() {
				console.log(this.delformopt);
				this.axios.get(this.actions.delbrand, {
					headers: {
						"Content-Type": "application/json",
					},
					params: this.delformopt
				}).then((response) => {
						console.log(response.data);
						if (response.data.errinfo == "OK") {
							this.$message.success(this.LANG.system.caozuochenggong);
							this.$router.go(0);
						} else {
							this.$message.success(this.PAGELANG[response.data.errinfo]);
						}
					})
				
			},
			RefreshDelMvDropdown() {
				this.axios.get(this.actions.getallbrand, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						pid: '0',
					}
				}).then((response) => {
					this.optcates = response.data;
					this.optcates.unshift({
						ID: '0',
						Name: '请选择品牌',
						Remark: '请选择品牌',
						Status: true,
					});
				});

			},
			Refresh() {
				this.isloading = true;
				this.axios.get(this.actions.getallbrand, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
					}
				}).then((response) => {
					this.isloading = false;
					console.log(response.data);
					for(var key in response.data) {
						response.data[key].ImageFullUrl = this.apibase + response.data[key].ImageUrl;
					}
					this.ListData = response.data;
				});
			},
			UpdateStatus(id, stat) {
				console.log(id + '-' + stat);
			},
			formatDate(date) {
				var datetime = new Date(date * 1000);
				var year = datetime.getFullYear();
				var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
				var day = ("0" + datetime.getDate()).slice(-2);
				return year + "-" + month + "-" + day;
			}
		},
		created: function() {
			this.Refresh();
		},
		components: {
			VuePictureCropper
		}
	}
